/* Estilos globais */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f0f4f8;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Tela de introdução */
.intro-screen {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  text-align: center;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

}

.intro-screen h1 {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #333;
}

.intro-screen span {
  font-size: 1rem;
  color: #666;
}

/* Botão de continuar */
.continue-btn {
  margin-top: 30px;
  background-color: #0066ff;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.continue-btn:hover {
  background-color: #0055cc;
}

.continue-btn:active {
  background-color: #0044aa;
}

/* Responsividade */
@media (max-width: 600px) {
  .intro-screen h1 {
    font-size: 2rem;
  }

  .intro-screen p {
    font-size: 1rem;
  }

  .continue-btn {
    font-size: 1rem;
    padding: 12px 24px;
  }
}