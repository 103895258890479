/* Contêiner do formulário */
form {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    display: flex;
    flex-direction: column;
}

/* Estilos gerais de input e select */
input,
select {
    padding: 15px;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    font-size: 16px;
    transition: border 0.3s ease;
    background-color: #f9fafb;
    outline: none;
}

label {
    margin-top: 20px;
}

input:focus,
select:focus {
    border-color: #0066ff;
}

/* Estilos dos placeholders */
::placeholder {
    color: #b0b3b8;
}

/* Estilos do botão de envio */
button {
    background-color: #0066ff;
    color: white;
    padding: 15px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
    margin-top: 30px;
}

button:hover {
    background-color: #0055cc;
}

/* Estilo de feedback após envio */
button:active {
    background-color: #0044aa;
}

/* Título do formulário */
form h2 {
    text-align: center;
    font-size: 24px;
    color: #333;
}

/* Estilo para o erro */
.error {
    color: red;
    font-size: 0.7rem;
    margin-top: 5px;
}
  

/* Responsividade */
@media (max-width: 600px) {
    select {
        width: 100%;
    }
}