/* src/components/Modal/Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .modal-content p {
    margin-bottom: 20px;
  }
  
  .modal-content button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background-color: #0056b3;
  }
  